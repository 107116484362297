// React
import React, { useEffect, useState } from 'react';

// Design
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, Typography, Input, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

// Internal

// Third-party
import axios from 'axios';

export default function GeneralSettingsDialog({ open, setOpen, appUser }) {
  const [operatorsCanEditNotes, setOperatorsCanEditNotes] = useState(false);
  const [sendOperatorNameInMessage, setSendOperatorNameInMessage] =
    useState(false);
  const [endingMessage, setEndingMessage] = useState('');
  const [scheduledAsMassive, setScheduledAsMassive] = useState(false);
  const [VMSRestrictionByTeam, setVMSRestrictionByTeam] = useState(false);
  const [showChatsToAllTeam, setShowChatsToAllTeam] = useState(false);
  const [returnsToSameTeam, setReturnsToSameTeam] = useState(false);
  const [blockAudio, setBlockAudio] = useState(false);
  const [
    operatorSeesOnlyTheirTeamClosingReasons,
    setOperatorSeesOnlyTheirTeamClosingReasons,
  ] = useState(false);
  const [maxStars, setMaxStars] = useState(0);
  const [showHistoryOnlyToSameTeam, setShowHistoryOnlyToSameTeam] =
    useState(false);
  const [
    supervisorCanDoActionsToClosedChats,
    setSupervisorCanDoActionsToClosedChats,
  ] = useState(false);

  const handleClose = () => {
    setOpen('');
  };

  const handleOperatorCanEditNotes = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/operators/edit-notes?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        // setOpen('');
        setOperatorsCanEditNotes(status);
      });
  };

  const handleSaveEndingMessage = (message) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/messages/ending-message?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        message,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setOperatorsCanEditNotes(message);
        setOpen('');
      });
  };

  const handleSaveMaxStars = (max) => {
    max = parseInt(max);
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/messages/max-stars?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        max,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setMaxStars(max);
        setOpen('');
      });
  };

  const handleSaveSendOperatorNameInMessage = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/messages/send-operator-name-in-message?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setSendOperatorNameInMessage(status);
        setOpen('');
      });
  };

  //

  const handleSaveRestrictionVMByTeam = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/messages/vms-restriction-by-team?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setVMSRestrictionByTeam(status);
        setOpen('');
      });
  };

  const handleSaveShowChatsToAllTeam = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/teams/show-chats-to-all-team?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setVMSRestrictionByTeam(status);
        setOpen('');
      });
  };

  const handleSaveSendScheduledAsMassive = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/messages/scheduled-as-massive?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setScheduledAsMassive(status);
        setOpen('');
      });
  };

  const handleReturnsToSameTeam = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/teams/returns-to-same-team?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setReturnsToSameTeam(status);
        setOpen('');
      });
  };

  const handleBlockAudio = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/interface/block-audio/?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setBlockAudio(status);
        setOpen('');
      });
  };

  const handleSaveShowHistoryOnlyToSameTeam = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/teams/show-history-only-to-same-team/?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setShowHistoryOnlyToSameTeam(status);
        setOpen('');
      });
  };

  const handleSaveOperatorSeesOnlyTheirTeamClosingReasons = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/interface/operator-sees-only-their-team-closing-reasons/?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setOperatorSeesOnlyTheirTeamClosingReasons(status);
        setOpen('');
      });
  };

  const handleSaveSupervisorCanDoActionsToClosedChats = (status) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/holders/company/interface/supervisor-can-do-actions-to-closed-chats/?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        status,
      },
    })
      .then((response) => {
        if (response.status === 202) {
        }
      })
      .catch(console)
      .finally((el) => {
        setSupervisorCanDoActionsToClosedChats(status);
        setOpen('');
      });
  };

  // /holders/company/
  const handleGetInfo = () => {
    axios({
      method: 'get',
      url: `https://interface.clickservices.com.br/auth/holders/company/general-settings?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
    })
      .then((response) => {
        if (response.status === 200 && response?.data?.info) {
          setOperatorsCanEditNotes(response?.data?.info?.operatorsCanEditNotes);
          setEndingMessage(response?.data?.info?.endingMessage);
          setScheduledAsMassive(response?.data?.info?.scheduledAsMassive);
          setSendOperatorNameInMessage(
            response?.data?.info?.sendOperatorNameInMessage
          );
          setVMSRestrictionByTeam(response?.data?.info?.vmsRestrictionByTeam);
          setShowChatsToAllTeam(response?.data?.info?.showChatsToAllTeam);
          setReturnsToSameTeam(response?.data?.info?.returnsToSameTeam);
          setBlockAudio(response?.data?.info?.blockAudio);
          setMaxStars(response?.data?.info?.maxStars ?? 0);
          setOperatorSeesOnlyTheirTeamClosingReasons(
            response?.data?.info?.operatorSeesOnlyTheirTeamClosingReasons
          );
          setShowHistoryOnlyToSameTeam(
            response?.data?.info?.showHistoryOnlyToSameTeam
          );
          setSupervisorCanDoActionsToClosedChats(
            response?.data?.info?.supervisorCanDoActionsToClosedChats
          );
        } else {
          // setCurrentList([])
        }
      })
      .catch(console)
      .finally((el) => {
        // setOpen('');
      });
  };

  useEffect(() => {
    if (open) {
      handleGetInfo();
      //   setSelectedAcceptedFile('');
    } else {
      //   setAcceptedFiles([]);
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Configurações Gerais</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={
                <Checkbox
                  checked={operatorsCanEditNotes}
                  onChange={(event) =>
                    handleOperatorCanEditNotes(event.target.checked)
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Operadores podem editar/criar/excluir notas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendOperatorNameInMessage}
                  onChange={(event) =>
                    handleSaveSendOperatorNameInMessage(event.target.checked)
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Enviar nome do operador no começo da mensagem"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={scheduledAsMassive}
                  onChange={(event) =>
                    handleSaveSendScheduledAsMassive(event.target.checked)
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                  disabled={true}
                />
              }
              label="Enviar agendamento como massivo"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={VMSRestrictionByTeam}
                  onChange={(event) =>
                    handleSaveRestrictionVMByTeam(event.target.checked)
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Restringir rota de saída às VMS do time"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showChatsToAllTeam}
                  onChange={(event) =>
                    handleSaveShowChatsToAllTeam(event.target.checked)
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Conversas são mostradas a todos do time"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={returnsToSameTeam}
                  onChange={(event) =>
                    handleReturnsToSameTeam(event.target.checked)
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Retornos vêm para o mesmo time"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={blockAudio}
                  onChange={(event) => handleBlockAudio(event.target.checked)}
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Bloquear o envio de áudio"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={operatorSeesOnlyTheirTeamClosingReasons}
                  onChange={(event) =>
                    handleSaveOperatorSeesOnlyTheirTeamClosingReasons(
                      event.target.checked
                    )
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Operador vê apenas as tabulações do seu time."
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showHistoryOnlyToSameTeam}
                  onChange={(event) =>
                    handleSaveShowHistoryOnlyToSameTeam(event.target.checked)
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Mostrar histórico apenas para o mesmo time."
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={supervisorCanDoActionsToClosedChats}
                  onChange={(event) =>
                    handleSaveSupervisorCanDoActionsToClosedChats(
                      event.target.checked
                    )
                  }
                  // disabled={column.id === 'allDay' && !row['active']}
                />
              }
              label="Supervisor pode fazer ações em chats fechados."
            />
            <Box
              sx={{
                border: '2px solid',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography textAlign={'center'}>
                <strong>Número máximo de favoritos</strong>
              </Typography>
              <Input
                fullWidth
                sx={{
                  backgroundColor: '#42a5f520',
                }}
                type="number"
                value={maxStars}
                onChange={(event) => setMaxStars(event.target.value)}
              />
              <Button onClick={() => handleSaveMaxStars(maxStars)} fullWidth>
                Salvar
              </Button>
            </Box>
            <Box
              sx={{
                border: '2px solid',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography textAlign={'center'}>
                <strong>Mensagem de Encerramento por Inatividade</strong>
              </Typography>
              <Input
                multiline
                fullWidth
                rows={6}
                sx={{
                  backgroundColor: '#42a5f520',
                }}
                value={endingMessage}
                onChange={(event) => setEndingMessage(event.target.value)}
              />
              <Button
                fullWidth
                onClick={() => handleSaveEndingMessage(endingMessage)}
              >
                Salvar
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
